import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PublicationProps } from '../../types/pages'
import { SectionBase } from '../../types/sections'
import { ResourceViewProps } from '../../types/resources'
// Components
import { Box } from '../../atoms'
import { Layout, Seo } from '../../components/commons'
import { ResourceView } from '../../views/resources'
import Section from '../../components/sections'

interface DataQuery {
  datoCmsResource: {
    locale: string
    meta: PublicationProps
    sections: SectionBase[]
  } & ResourceViewProps
}

const Resource: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    datoCmsResource: { locale, meta, sections, ...resourceData },
  } = data

  return (
    <Layout headerDark={resourceData.pageInfo.headerDark}>
      <Seo slug={resourceData.pageInfo.slug} publication={meta} lang={locale} content={resourceData.pageInfo.seo} />

      <Box pt={[120, 120, 160]}>
        <ResourceView {...resourceData} />
      </Box>

      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} sectionApiKey={s.model.apiKey} data={s} />)}
    </Layout>
  )
}

export const query = graphql`
  query ResourceQuery($id: String!) {
    datoCmsResource(id: { eq: $id }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      ...resourceDataFragment
    }
  }
`

export default Resource
